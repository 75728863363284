import { useEffect, useState } from "react"; // This is how you import useEffect & useState

import MovieCard from './MovieCard'; // This is the new JSX I created for the movies

import './App.css'; // This is how you import the CSS tyle

import SearchIcon from './search.svg' // This is how to import the search icon

const API_URL = 'http://www.omdbapi.com?apikey=fdc8e723' // This is how you call an API

const movie = {
    "Title": "Italian Spiderman",
    "Year": "2007",
    "imdbID": "tt2705436",
    "Type": "movie",
    "Poster": "https://m.media-amazon.com/images/M/MV5BZWQxMjcwNjItZjI0ZC00ZTc4LWIwMzItM2Q0YTZhNzI3NzdlXkEyXkFqcGdeQXVyMTA0MTM5NjI2._V1_SX300.jpg"
}

const App = () => {
    const [movies, setMovies] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');

    const searchMovies = async (title) => { //Research this
        const response = await fetch(`${API_URL}&s=${title}`);
        const data = await response.json();

        setMovies(data.Search);
    }

    useEffect(() => {
        searchMovies('Spiderman');
    }, []);

    return (
        <div className="app">
            <h1>MovieLandia</h1>

            <div className="search">
                <input 
                    placeholder="Search for movies"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    onKeyDown={(e) => { // I added this functionality to search using the enter
                        if (e.key === 'Enter') {
                        searchMovies(searchTerm);
                        }
                    }}
                />
                <img 
                    src={SearchIcon}
                    alt="search"
                    onClick={() => searchMovies(searchTerm)} // Research here
                />
            </div>

            {movies?.length > 0
            ? (
              <div className="container">
                  {movies.map((movie) => ( //Research the .map functionality
                    <MovieCard movie={movie} />
               ))}
                </div>
                ) : (
                  <div className="empty">
                       <h2>No movies found.</h2>
                   </div>
                )} 

           
        
        </div>
    );
}

export default App;